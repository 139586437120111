<template>
    <input
        ref="inputRef"
        class="form-accountant-select-client"
        @change="onChange"
    />
</template>
<script setup>
import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/dist/tagify.css'
import { useApi } from '@tenant/composables'
import { useDebounceFn } from '@vueuse/core'
import { asset } from '@tenant/core/filter'

const props = defineProps({
    modelValue: {
        default: null,
    },
    valueProperty: {
        type: String,
        default: 'id',
    },
    tagifyProps: {
        type: Object,
        default: () => ({}),
    },
})
const emits = defineEmits(['update:modelValue'])

const { t } = useI18n()

const inputRef = ref(null)
const tagify = ref(null)

const onLoaded = (items) => {
    tagify.value = new Tagify(inputRef.value, {
        templates: {
            dropdownItemNoMatch: function () {
                return `<div
                    class='text-sm py-2.5 px-3.5 flex items-center text-sm font-normal text-gray-500 text-center'
                    value="noMatch"
                    tabindex="0"
                    role="option"
                >
                    ${t('accountantInbox.noClientFound')}
                </div>`
            },
            tag: function (tagData) {
                if (tagData.__isValid !== true) {
                    return `<span class="text-danger-500 font-normal text-md">
                            ${t('general.invalid')}
                        </span>`
                }

                return `<tag title='${
                    tagData?.fullName ?? tagData.value
                }' contenteditable="false" spellcheck="false"
                    class='flex px-1.5 py-1 rounded-full bg-gray-100 items-center gap-1.5 ${
                        tagData.class ? tagData.class : ''
                    }' ${this.getAttributes(tagData)}>
                    <div class="bg-gray-50 w-4 h-4 rounded-full p-0.5 flex items-center justify-center">
                        <img
                            class="w-full"
                            src="${asset(
                                'static/icons/line-icons/users/user-01.svg'
                            )}"
                        />
                    </div>
                    <span class="text-gray-700 text-sm font-semibold whitespace-nowrap">
                        ${tagData?.fullName ?? tagData.value}
                    </span>
                    <x title='remove tag' class='tagify__tag__removeBtn'/>
                </tag>`
            },
            dropdownItem: function (tag) {
                let select = false
                const { value } = this
                if (value && value.length > 0) {
                    select = value.findIndex((i) => i.value === tag.value) > -1
                }

                return `
                    <div ${this.getAttributes(tag)}
                        class='flex items-center justify-between tagify__dropdown__item border-b  border-gray-200 ${
                            tag.class ? tag.class : ''
                        } ${select ? 'bg-gray-50' : ''}'
                        tabindex="0"
                        role="option"
                    >
                        <div class="flex flex-col">
                            <strong class="block text-gray-700 text-md font-semibold whitespace-nowrap">
                                ${tag.fullName}
                            </strong>
                            <span class="block text-gray-500 text-sm font-normal whitespace-nowrap">
                                ${tag.email}
                            </span>
                        </div>
                        ${
                            select
                                ? `
                                <div class="sas-icon text-primary-600">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M20 6L9 17L4 12"
                                            stroke="black"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                            `
                                : ''
                        }
                    </div>
                `
            },
        },
        skipInvalid: true,
        maxTags: 1,
        enforceWhitelist: true,
        whitelist: items,
        dropdown: {
            includeSelectedTags: true,
            enabled: 0,
            maxItems: 10,
            classname: 'dropdown-properties',
            searchKeys: ['fullName', 'email', 'companyName'],
        },
        originalInputValueFormat: (l) => l.map((i) => i.value).join(','),
        ...props.tagifyProps,
    })

    tagify.value.loadOriginalValues(props.modelValue)
    tagify.value.on('input', onInput)
    tagify.value.on('remove', onRemoveTag)
}

const onInput = (e) => {
    tagify.value.whitelist = null
    tagify.value.loading(true).dropdown.hide()
    onChangeInput(e.detail.value)
}

const onChangeInput = useDebounceFn((searchText) => {
    loadData(searchText).then((items) => {
        tagify.value.whitelist = items
        tagify.value.loading(false).dropdown.show(searchText)
    })
}, 300)
const { execute } = useApi('/api/accountant/clients/users', 'GET')

onMounted(() => {
    loadData().then(onLoaded)
})

const onRemoveTag = () => {
    loadData().then((items) => {
        tagify.value.whitelist = items
        tagify.value.loading(false).dropdown.show()
    })
}

const loadData = (search = null) => {
    return execute({
        queries: {
            q: search || null,
        },
    }).then(({ data }) => {
        return data.map((i) => ({
            value: i[props.valueProperty],
            id: i[props.valueProperty],
            fullName: i.full_name,
            email: i.email,
            companyName: i.tenants[0].name || null,
        }))
    })
}

const onChange = (e) => {
    emits('update:modelValue', e.target?.value?.split(',')[0] || null)
}
</script>
<style lang="scss">
.form-accountant-select-client {
    @apply flex w-full items-center gap-1 border-0 text-md text-gray-900 placeholder-gray-500;
    .tagify__input {
        @apply m-0;
    }

    &.tagify--loading {
        .tagify__input {
            &:after {
                @apply mt-1;
            }
        }
    }
}

.tagify__dropdown.dropdown-properties {
    .tagify__dropdown__wrapper {
        @apply m-1 rounded-lg border-gray-100 shadow-sm;
        .tagify__dropdown__item {
            @apply m-0 px-4 py-2 text-md font-normal text-gray-900;

            &--active {
                @apply bg-gray-50;
            }
        }
    }
}

.tagify__tag__removeBtn {
    height: 14px;
    width: 14px;
    --tag-remove-btn-color: #98a2b3;
}
</style>
